<template>
  <div>
    <input type="file" ref="file" @change="handleFileUpload">  
    <button @click="submitFile">Upload</button>
    
  </div>  
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
       file: ''
    };
  },
  created() {
  },
  methods: {
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    submitFile() {
      let formData = new FormData();
      formData.append('source', this.file);
      
      this.$api.post('/upload/source', formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      ).then(function(){
        console.log('SUCCESS!!');
      })
      .catch(function(){
        console.log('FAILURE!!');
      });
    }
  },
  watch: {
  }
};
</script>

<style scoped lang="scss">
</style>